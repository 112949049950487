<template>
  <div class="container-fluid">
    <h4 class="mx-auto mt-3 mb-4">
      購物車
    </h4>
    <div
      v-for="(detail,index) in cart"
      :key="index"
      class="row align-items-center mb-5 mb-md-2"
    >
      <div class="col-1 text-left text-md-center">
        <div class="custom-control custom-checkbox">
          <input
            :id="detail.cartID"
            v-model="SelectedCartList"
            :disabled="detail.storageQty <= 0"
            type="checkbox"
            name="cartID"
            :value="detail.cartID"
            class="form-check-input"
          >
          <label
            class="custom-control-label"
            :for="detail.cartID"
          />
        </div>
      </div>
      <div class="text-center col-4 col-md-2 col-lg-2">
        <img
          :src="detail.mainImg ?? require('@/assets/img/nopic.gif')"
          class="w-100"
          @error="imageLoadError"
        >
      </div>
      <router-link
        :to="'/product/detail/' + detail.productID"
        class="link-primary col-6 col-md-5 col-lg-4 text-decoration-none text-start"
      >
        {{ detail.name }}
        <br>{{ detail.spec1Name?detail.spec1Name + '：' : '' }}{{ detail.spec1Value?detail.spec1Value: '' }}{{ detail.spec2Name? '，' + detail.spec2Name + '：' : '' }}{{ detail.spec2Value?detail.spec2Value: '' }}
      </router-link>
      <div class="d-block d-md-none w-100 my-2" />
      <div
        v-if="detail.storageQty>0"
        class="text-center col-7 col-md-2 col-lg-2 offset-1 offset-md-0 p-md-0 position-relative"
      >
        <!-- <div class="input-group">
        <div class="input-group-prepend pointer">
          <span
            class="input-group-text border-right-0 bg-white"
            @click="updateCartItem(detail.cartID, detail.qty-1, false, detail.storageQty, detail)"
          ><i
            data-feather="minus"
            class="btn-icon mx-2"
          /></span>
        </div>
        <input
          v-model="detail.qty"
          type="number"
          min="1"
          :max="detail.storageQty"
          class="form-control text-center border-left-0 border-right-0"
          placeholder="數量"
          name="qty"
          aria-label="數量"
          @change="validProNum(detail.cartID, detail.qty, detail.storageQty)"
        >
        <div class="input-group-append pointer">
          <span
            class="input-group-text border-left-0 bg-white"
            @click="updateCartItem(detail.cartID, detail.qty+1, false, detail.storageQty, detail)"
          ><i
            data-feather="plus"
            class="btn-icon mx-2"
          /></span>
        </div>
      </div> -->
        <div class="input-group align-items-center border">
          <div
            role="button"
            class="px-3 py-2"
            @click="updateCartItem(detail.cartID, detail.qty-1, detail.storageQty)"
          >
            <span
              class="border-0 bg-white"
            >
              <Icon
                name="minus"
                :class="$style[`icon`]"
              />
            </span>
          </div>
          <input
            v-model="detail.qty"
            type="number"
            min="1"
            :max="detail.storageQty"
            class="form-control text-center border-0"
            placeholder="數量"
            name="qty"
            aria-label="數量"
            @change="updateCartItem(detail.cartID, detail.qty, detail.storageQty)"
          >
          <div
            role="button"
            class="px-3 py-2"
            @click="updateCartItem(detail.cartID, detail.qty+1, detail.storageQty)"
          >
            <span
              class="border-0 bg-white"
            ><Icon
              name="plus"
              :class="$style[`icon`]"
            /></span>
          </div>
        </div>
        <div class="position-absolute end-0">
          <small
            class="text-danger"
            :class="[$style[`error-text`],{[$style[`show`]]: v$.cart.$error}]"
          >{{ v$.cart?.$errors[0]?.$message[index][0] || '' }}</small>
        </div>
      </div>
      <div
        v-else
        class="text-center col-7 col-md-2 col-lg-2 offset-1 offset-md-0 p-md-0 text-danger"
      >
        商品目前無庫存。
      </div>
      <div class="col-2 col-md-1 text-center">
        ${{ detail.fixedPrice * detail.qty ?? 0 }}
      </div>
      <div class="text-center col-1">
        <div
          role="button"
          @click="deleteCartItem(detail.cartID)"
        >
          <Icon
            name="cross"
            :class="$style[`icon`]"
          />
        </div>
      </div>
    </div>
    <hr>
    <div class="d-flex justify-content-center my-5">
      <button
        type="button"
        class="btn btn-outline-primary rounded-0 mx-2"
        @click="back()"
      >
        上一頁
      </button>
      <button
        class="btn btn-primary text-white rounded-0 mx-2"
        type="button"
        @click="readCheckoutCart"
      >
        去結帳
      </button>
    </div>
  </div>
</template>
<script>
import { reactive, inject, computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import useVuelidate from '@vuelidate/core'
import { helpers, requiredHelper, numericHelper } from '@/hooks/useVuelidate.js'
import { useErrorMessage } from '@/hooks/errorMessage.js'

export default {
  name: 'Cart',
  components: {

  },
  async setup () {
    const { getters, dispatch, commit } = useStore()
    const { push, back } = useRouter()
    const { getErrorMessage, getSuccessMessage } = useErrorMessage()

    const memberID = computed(() => getters['member/getMemberID'])
    const cart = computed(() => getters['cart/getCart'])

    const setAlert = inject('setAlert')

    const SelectedCartList = ref([])

    const imageLoadError = (e) => {
      e.target.src = require('@/assets/img/nopic.gif')
    }

    const state = reactive({
      cart,
      SelectedCartList
    })

    const rules = {
      cart: {
        $each: helpers.forEach({
          qty: { requiredHelper, numericHelper }
        })
      },
      SelectedCartList: { requiredHelper }
    }

    const v$ = useVuelidate(rules, state)
    v$.value.$touch()

    const readCart = async () => {
      if (!memberID.value) {
        setAlert(true, false, '請登入會員！')
        window.setTimeout(() => push('/login'), 2000)
        return null
      }
      const payload = { memberID: memberID.value, offset: 0, pageSize: 10 }

      try {
        const response = await dispatch('cart/readCart', { payload })
        if (response.data.code !== 200) {
          // setAlert(true, false, response.data.errMsg)
        }
      } catch (error) {
        console.log('send contact form error:' + ', ' + error)
        // setAlert(true, false, error)
      }
    }

    const readCartMount = async () => {
      if (memberID.value) {
        const payload = { memberID: memberID.value }

        try {
          const response = await dispatch('cart/readCartMount', { payload })
          if (response.data.code !== 200) {
          // setAlert(true, false, response.data.errMsg)
          }
        } catch (error) {
          console.log('send contact form error:' + ', ' + error)
        // setAlert(true, false, error)
        }
      } else {
        commit('cart/setCartMount', { data: 0 })
      }
    }

    const deleteCartItem = async (cartID) => {
      if (!memberID.value) {
        setAlert(true, false, '請登入會員！')
        window.setTimeout(() => push('/login'), 2000)
        return null
      }
      const payload = { memberID: memberID.value, cartID }

      // 刪除
      try {
        const response = await dispatch('cart/deleteCartItem', { payload })

        // getErrorMessage
        if (response.data.errMsg) {
          setAlert(true, false, getErrorMessage(response))
        } else {
          setAlert(true, true, getSuccessMessage(response))
          await readCart()
          await readCartMount()
        }

        return response
      } catch (error) {
        // console.log('get user info error: ' + ', ' + error)
        setAlert(true, false, `刪除失敗: ${error}`)
      }
    }

    // 檢核手動輸入
    const validProNum = (cartID, qty, storageQty) => {
      if (qty > storageQty) {
        setAlert(true, false, '商品數量超過庫存。')
        return false
      } else if (qty <= 0) {
        setAlert(true, false, '商品數量不能為0。')
        return false
      } else {
        return true
      }
    }

    // 商品異動
    const updateCartItem = async (cartID, qty, storageQty) => {
      // const sendData = {}
      if (!memberID.value) {
        setAlert(true, false, '請登入會員！')
        window.setTimeout(() => location.replace('/login'), 2000)
        return null
      }
      if (validProNum(cartID, qty, storageQty)) {
      // 數量異動
      // if (num > storageQty) {
      //   setAlert(true, false, '商品數量超過庫存。')
      //   return null
      // }

        // if (num <= 0) {
        //   setAlert(true, false, '商品數量不能為0。')
        //   return null
        // }

        const payload = { memberID: memberID.value, cartID, qty }

        // 刪除
        try {
          const response = await dispatch('cart/updateCartItem', { payload })

          // getErrorMessage
          if (response.data.errMsg) {
            setAlert(true, false, getErrorMessage(response))
          } else {
            setAlert(true, true, getSuccessMessage(response))
            await readCart()
            await readCartMount()
          }
        } catch (error) {
          setAlert(true, false, `更新失敗: ${error}`)
        }
      }
    }

    const readCheckoutCart = async () => {
      if (!v$.value.$error) {
        const payload = {
          idList: SelectedCartList.value,
          offset: 0,
          pageSize: 20
        }

        // 刪除
        try {
          await dispatch('cart/readCheckoutCart', { payload })
          push('/checkout')
        } catch (error) {
        // console.log('get user info error: ' + ', ' + error)
          setAlert(true, false, error)
        }
      } else {
        setAlert(true, false, '請至少選擇一樣商品')
      }
    }

    await readCart()

    return {
      imageLoadError,
      readCheckoutCart,
      back,
      validProNum,
      cart,
      deleteCartItem,
      updateCartItem,
      SelectedCartList,
      v$
    }
  }
}
</script>
<style lang="scss" module>

.icon {
  height: 1.2rem;
  padding: 0.2rem;
  width: 1.2rem;
}

.error-text {
  display: inline-block;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.3s;
}

.error-text.show {
  opacity: 1;
}
</style>
